///
/// Vuex Module - User
///

import Vue from 'vue'
import { API } from '@/lib/api'
import * as types from '../types'
import { auth } from '@/lib/auth'
import { flatten, cleanObject } from '@/lib/utils'

/// STATE
const state = () => ({
	me: auth.getUser(),
	myData: {},
	user: {},
	users: [],
	verify: {},
})

/// ACTIONS
const actions = {
	async login({ commit, dispatch }, credentials ) {
		let login
		try {
			login = await API.send(`/users/login`, credentials, false)
			if (login.token && !login.needsOtp) {
				commit(types.LOGIN_USER, login)
			}

			if (login.needsOtp) {
				commit(types.SET_VERIFY, login)
			}
		} catch (error) {
			this.dispatch('misc/setError', error)
			throw error
		}

		return login
	},
	async logout({ commit }) {
		commit(types.LOGOUT_USER)
	},
	async verify({ commit, state }, code) {
		let login
		try {
			const headers = {
				Authorization: `Bearer ${state.verify.token}`
			}

			login = await API.send(`/users/verify`, code, false, headers)
			if (login.token && !login.needsOtp) {
				commit(types.LOGIN_USER, login)
			}
		} catch (error) {
			this.dispatch('misc/setError', error)
			throw error
		}

		return login
	},
	async register({ commit, dispatch }, registration ) {
		let registered
		try {
			registered = await API.send(`/registrations`, registration, false)
		} catch (error) {
			console.error(error)
			// throw new Error(error.message || error)
			return this.dispatch('misc/setError', error.message || error)
		}

		return registered
	},
	async recover({ commit }, email) {
		try {
			await API.send(`/users/recover`, { email }, false)
		} catch (error) {
			return this.dispatch(`misc/setError`, error.message || error)
		}
	},
	async reset({ commit }, { token, password, passwordConfirm }) {
		try {
			await API.send(`/users/reset`, { password, passwordConfirm }, false, { Authorization: `Bearer ${token}`})
		} catch (error) {
			this.dispatch(`misc/setError`, error.message || error)
			throw new Error(error.message || error)
		}
	},
	async resetMe({ commit }, { password, newPassword, newPasswordConfirm }) {
		try {
			await API.send(`/users/me/reset`, { password, newPassword, newPasswordConfirm }, true)
		} catch (error) {
			throw new Error(error.message || error)
		}
	},
	async updateMe({ commit }, { firstName, lastName, address, phone, settings }) {
		try {
			await API.update(`/users/me`, { firstName, lastName, address, phone, settings }, true)
		} catch (error) {
			throw new Error(error.message || error)
		}
	},
	async trackMe({ commit }, { event_name, app }) {
		try {
			await API.send(`/users/me/track`, { event_name, event_data: { app }})
		} catch (error) {
			throw new Error(error.message || error)
		}
	},
	async updateEmail({ commit }, { email, newEmail, password}) {
		let res
		try {
			res = await API.send(`/users/me/email`, { email, newEmail, password}, true)
		} catch (error) {
			throw new Error(error.message || error)
		}
		return res
	},
	async confirm({ commit, dispatch }, credentials) {
		let login
		try {
			login = await API.send(`/users/me/confirm`, credentials, false)
			if (login.token) {
				commit(types.LOGIN_USER, login)
			}
		} catch (error) {
			this.dispatch('misc/setError', error)
			throw error
		}

		return login
	},
	async getData({ commit }, uid) {
		try {
			const userData = await API.get(`/users/me`)
			commit(types.SET_ME, userData)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
		}
	},
	async list({ commit }, params) {
		this.dispatch('misc/setLoading', true)

		let users
		try {
			users = await API.get(`/users`, params)
			commit(types.SET_USERS, users)
			this.dispatch('misc/setLoading', false)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw error
		}

		return users
	},
	async get({ commit }, uid) {
		this.dispatch('misc/setLoading', true)

		try {
			const user = await API.get(`/users/${uid}`)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_USER, user)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw error
		}
	},
	async create({ commit }, data) {
		this.dispatch('misc/setLoading', true)

		try {
			const user = await API.send(`/users`, data)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_USER, user)
			return user
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw error
		}
	},
	async remove({ commit }, uid) {
		try {
			await API.remove(`/users/${uid}`)
			commit(types.UNSET_USER)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}
	},
	async update({ commit }, { uid, data }) {
		this.dispatch('misc/setLoading', true)

		try {
			const user = await API.update(`/users/${uid}`, data)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_USER, user)
			return user
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw error
		}
	},
	async report({ commit }, params) {
		this.dispatch('misc/setLoading', true)

		params.report = true
		let users = []
		try {
			users = await API.get(`/users`, params)
			this.dispatch('misc/setLoading', false)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw error
		}

		let csv = ''
		if (users.length) {
			let rows = []
			let header =[]
			users.forEach(row => {
				let flat = flatten(row)
				let head = Object.keys(flat)
				header = [...new Set([...header, ...head])]
				rows.push(flat)
			})

			csv = [
				header,
				...rows.map(row => header.map(f => {
					return JSON.stringify(row[f])
				}))
			].join(`\r\n`)
			return csv
		}

		return csv
	},
	async salesforce({ commit }, uid) {
		this.dispatch('misc/setLoading', true)
		try {
			const user = await API.get(`/users/${uid}/salesforce`)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_USER, user)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw error
		}
	},
	async salesforceQuery({ commit, }, { email, }) {
		this.dispatch('misc/setLoading', true)

		try {
			const res = await API.get(`/users/salesforce`, { email, })
			return res
		} catch (error) {
			this.dispatch('misc/setError', error)
		} finally {
			this.dispatch('misc/setLoading', false)
		}
	},
	async resetFail({ commit }, uid) {
		this.dispatch('misc/setLoading', true)

		try {
			const user = await API.send(`/users/${uid}/resetFail`)
			this.dispatch('misc/setLoading', false)
			return commit(types.SET_USER, user)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw error
		}
	},
	async resetMfa({ commit }, uid) {
		this.dispatch('misc/setLoading', true)

		try {
			const user = await API.send(`/users/${uid}/resetMfa`)
			this.dispatch('misc/setLoading', false)
			return commit(types.SET_USER, user)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw error
		}
	},
	async unset({ commit }) {
		commit(types.UNSET_USER)
	},
}

/// MUTATIONS
const mutations = {
	[types.LOGIN_USER](state, user) {
		state.me = user
		auth.login(user)
	},
	[types.LOGOUT_USER](state) {
		auth.logout()
		state.me = {}
		state.myData = {}
	},
	[types.SET_ME](state, userData) {
		state.myData = cleanObject(userData)
	},
	[types.SET_USER](state, user) {
		state.user = cleanObject(user)

		const idx = state.users.findIndex(u => u.uid == user.uid)
		if (idx > -1){
			state.users[idx] = cleanObject(user)
		} else {
			state.users.push(cleanObject(user))
		}
		state.users = [...state.users]
	},
	[types.SET_USERS](state, users) {
		state.users = users.map(u => cleanObject(u))
	},
	[types.UNSET_USER](state) {
		state.user = {}
	},
	[types.SET_VERIFY](state, verify) {
		state.verify = verify
	},
}

/// GETTERS
const getters = {
	me: state => state.me,
	myData: state => state.myData,
	user: state => state.user,
	users: state => state.users,
	token: state => state.me.token,
	verify: state => state.verify,
}

/// EXPORT
export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
